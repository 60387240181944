@import '~antd/dist/antd.css';

body {
    background: #e65c00;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #F9D423, #e65c00);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #F9D423, #e65c00); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.login-form {
    max-width: 400px;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}