/* @import '../node_modules/leaflet/dist/leaflet.css'; */

body {
	color: #FFF;
	margin: 0;
	padding: 0;
}

nav {
	width: 3vw;
	position: absolute;
	/* -ms-transform: translateY(-50%); */
	/* transform: translateY(-50%); */
	text-align: center;
	background:#00afb9;
	font-size: 1rem;
	z-index: 9999;
	bottom: 15px;
	left: 15px;
	border-radius: 0.2rem;
	/* border: 1px solid #03969e; */
}

nav li {
	list-style: none;
	margin: 0;
	padding: 0;
	cursor: pointer;
}

nav li:hover {
	background: rgba(255, 255, 255, 0.2);
}
/* rgba(255, 255, 255, 0.8); */
nav li.ativo {
	background: #ffca3a;
	/* color: #00afb9; */
	/* color: #ffca3a; */
}

nav li svg {
	margin: 8px 0px;
	padding: 0;
}

#mapa {
	width: 100vw;
	height: 100vh;
	float: right;
}

.wrapper
{
	/* padding-bottom: 90px; */
}

.divider
{
	position: relative;
	height: 1px;
}

.div-transparent:before
{
	content: "";
	position: absolute;
	top: 0;
	left: 5%;
	right: 5%;
	width: 90%;
	height: 1px;
	background-image: linear-gradient(to right, transparent, #4ecdc4, transparent);
}

.custom.icon {
	color: #00afb9;
	font-size: 1rem;
}

.crop-container {
	position: absolute;
	z-index: 9999;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
}